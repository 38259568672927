'use strict';

require('hc-sticky');
const _ = require('underscore');
const breakpoints = require('../components/breakpoints');

const $siteHeader = $('.siteheader');
const $gridHeader = $('.grid-header');
let $productGrid = $('.product-grid');
const $refineBar = $('.refinement-bar');
const $filterCategories = $('.refinement-category');

const gridHeaderTop = $gridHeader.length ? $gridHeader.offset().top : null;
let lastScrollTop = 0;
let refineBarOffsetTop;
let gridHeaderHeight;

/**
 * if Refinement Categories are present above Filters, factor that height into the top positioning,
 * if Refinement Categories are hidden (outerHeight = 0) or omitted above Filters, factor in the breadcrumbs and search results header height into the top positioning
 */
function calculateGridHeaderOffset() {
    gridHeaderHeight = $gridHeader.outerHeight(true);
    refineBarOffsetTop = $filterCategories.length && $filterCategories.outerHeight() ? $filterCategories.offset().top + $filterCategories.outerHeight() : gridHeaderTop;
}

/**
 * reposition the refinement bar on Filter click
 * @param {string} customEvent custom event triggered
 * @param {Integer} offset offset position of Refinement Bar
 */
function triggerAlternatePosition(customEvent, offset) {
    $(document).trigger(customEvent, offset);
}

/**
* on scroll down
*/
function scrollDownHandler() {
    // set offset values
    calculateGridHeaderOffset();

    const windowHeight = $(window).height();
    const scrollTop = $(document).scrollTop();
    const siteHeaderHeight = $siteHeader.outerHeight();
    const scrollTopWithHeader = scrollTop + siteHeaderHeight;
    const refineBarHeight = $refineBar.outerHeight();
    const refineBarTop = $refineBar.offset().top;
    const refineBarBottom = refineBarTop + refineBarHeight;
    const gridHeaderBottom = gridHeaderTop + gridHeaderHeight;
    const windowThreshhold = (windowHeight - siteHeaderHeight) - refineBarHeight; // for checking the space between window height and refinement bar height

    if (refineBarHeight < $productGrid.outerHeight()) {
        if (refineBarHeight < windowHeight - siteHeaderHeight) {
            // refinement bar can fit within the viewport
            if (scrollTopWithHeader + refineBarHeight >= gridHeaderBottom) {
                // refinement bar bottom is at the bottom of the page
                triggerAlternatePosition('search:absolute', 0);
            } else if (scrollTopWithHeader > refineBarTop && windowThreshhold >= 0) {
                triggerAlternatePosition('search:fixed', siteHeaderHeight);
            }
        } else if (scrollTopWithHeader + refineBarHeight >= gridHeaderBottom) {
            // refinement bar bottom is at the bottom of the page
            triggerAlternatePosition('search:absolute', 0);
        } else if (scrollTopWithHeader <= refineBarOffsetTop) {
            // scroll position is above grid fold
            triggerAlternatePosition('search:relative', 0);
        } else if (refineBarBottom - windowHeight - scrollTop > 0 && windowThreshhold <= 0) {
            if ($refineBar.is('.filter-position-fixed, .filter-position-absolute')) {
                triggerAlternatePosition('search:relative', refineBarTop - refineBarOffsetTop);
            }
        } else if (refineBarBottom - windowHeight - scrollTop < 0) {
            triggerAlternatePosition('search:fixed', windowHeight - refineBarHeight);
        }
    } else {
        triggerAlternatePosition('search:relative', 0);
    }
}

/**
* on scroll up
*/
function scrollUpHandler() {
    // set offset values
    calculateGridHeaderOffset();

    const scrollTop = $(document).scrollTop();
    const siteHeaderHeight = $siteHeader.outerHeight();
    const scrollTopWithHeader = scrollTop + siteHeaderHeight;
    const refineBarTop = $refineBar.offset().top;

    if (scrollTopWithHeader >= refineBarOffsetTop) {
        // scroll top is below the refinement-bar top
        if ($refineBar.is('.filter-position-fixed, .filter-position-absolute')) {
            if (scrollTopWithHeader > refineBarTop) {
                triggerAlternatePosition('search:relative', refineBarTop - refineBarOffsetTop);
            }
        } else if (scrollTopWithHeader - refineBarTop <= 0) {
            triggerAlternatePosition('search:fixed', siteHeaderHeight);
        }
    } else {
        // scroll top is at the top portion of the page
        triggerAlternatePosition('search:relative', 0);
    }
}

/**
* calculate product grid & refinement bar position
*/
function calculateGridPosition() {
    const scrollTop = $(document).scrollTop();

    if (scrollTop > lastScrollTop) {
        scrollDownHandler();
    } else if (scrollTop < lastScrollTop) {
        scrollUpHandler();
    }
    lastScrollTop = scrollTop;
}

/**
 * Destroy sticky components
 */
function destroyStickyPLPComponents() {
    $('.js-filter-bar').hcSticky('destroy');
}

/**
 * Update sticky components on PLP for persistent filters
 */
function updateStickyPLPComponents() {
    const animationBuffer = 16; // 16px = 1rem, buffer prevents content from showing between header and filter bar during animation
    if ($(window).scrollTop() > 0) {
        $('.js-filter-bar').hcSticky('update', {
            top: $siteHeader.outerHeight() - animationBuffer
        });
    }
}

/**
 * Initialize sticky components on PLP for persistent filters
 */
function initStickyPLPComponents() {
    $('.js-filter-bar').hcSticky({
        stickTo: '.js-plp-sticky-row'
    });

    document.addEventListener('scroll', updateStickyPLPComponents, { passive: true });
    $(window).on('widthResize', updateStickyPLPComponents);
}

/**
 * initialize floatingFilterNav
 */
function init() {
    // reinitialize .product-grid reference when lost on product grid refresh
    $productGrid = $('.product-grid');

    if (breakpoints.isHighRes() && $productGrid.length) {
        let scrollDirection;
        calculateGridPosition();

        // fire scroll handlers sooner when scroll direction changes
        $(window).scroll(_.debounce(function () {
            const scrollTop = $(document).scrollTop();
            if (scrollTop > lastScrollTop) {
                if (scrollDirection && scrollDirection !== 'down') {
                    scrollDownHandler();
                }
                scrollDirection = 'down';
            } else if (scrollTop < lastScrollTop) {
                if (scrollDirection && scrollDirection !== 'up') {
                    scrollUpHandler();
                }
                scrollDirection = 'up';
            }
        }, 5));

        $(window).scroll(_.throttle(function () {
            calculateGridPosition();
        }, 100));

        // alternate between relative, fixed, and absolute positioning based on scroll direction and positioning
        $(document).on('search:relative', function (e, offset) {
            $refineBar.removeClass('filter-position-fixed filter-position-absolute').css('bottom', '').css('top', offset);
        });
        $(document).on('search:fixed', function (e, offset) {
            $refineBar.removeClass('filter-position-absolute').addClass('filter-position-fixed').css('bottom', '').css('top', offset);
        });
        $(document).on('search:absolute', function (e, offset) {
            $refineBar.removeClass('filter-position-fixed').addClass('filter-position-absolute').css('top', '').css('bottom', offset);
        });

        // on filter click
        $(document).on('filter:click', '.js-refinements .refinement .title', function () {
            scrollDownHandler();
        });

        // on LazyLoad complete
        $(document).on('search:lazyLoadSuccess', function () {
            scrollDownHandler();
            scrollUpHandler();
        });
    }
}

module.exports = {
    initStickyPLPComponents: initStickyPLPComponents,
    destroyStickyPLPComponents: destroyStickyPLPComponents,
    calculateGridPosition: calculateGridPosition,
    scrollUpHandler: scrollUpHandler,
    scrollDownHandler: scrollDownHandler,
    init: init
};
